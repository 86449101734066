@font-face {
    font-family: 'FbPractica_black';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(../fonts/FbPractica-Black.eot);
    src: url(../fonts/FbPractica-Black.otf);
    src: url(../fonts/FbPractica-Black.otf) format('embedded-opentype'), url(../fonts/FbPractica-Black.otf) format('otf'), url(../fonts/FbPractica-Black.otf) format('truetype');
}

@font-face {
    font-family: 'FbPractica_Bold';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(../fonts/FbPractica-Bold.eot);
    src: url(../fonts/FbPractica-Bold.otf);
    src: url(../fonts/FbPractica-Bold.otf) format('embedded-opentype'), url(../fonts/FbPractica-Bold.otf) format('otf'), url(../fonts/FbPractica-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'FbPractica_Light';
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
    src: url(../fonts/FbPractica-Light.eot);
    src: url(../fonts/FbPractica-Light.otf);
    src: url(../fonts/FbPractica-Light.otf) format('embedded-opentype'), url(../fonts/FbPractica-Light.otf) format('otf'), url(../fonts/FbPractica-Light.otf) format('truetype');
}

@font-face {
    font-family: 'FbPractica_Regular';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(../fonts/FbPractica-Regular.eot);
    src: url(../fonts/FbPractica-Regular.otf);
    src: url(../fonts/FbPractica-Regular.otf) format('embedded-opentype'), url(../fonts/FbPractica-Regular.otf) format('otf'), url(../fonts/FbPractica-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'FbEmeq-Regular';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url(../fonts/FbEmeq-Regular.otf);
    src: url(../fonts/FbEmeq-Regular.otf) format('embedded-opentype'), url(../fonts/FbEmeq-Regular.otf) format('otf'), url(../fonts/FbEmeq-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'FbEmeq-Bold';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url(../fonts/FbEmeq-Bold.otf);
    src: url(../fonts/FbEmeq-Bold.otf) format('embedded-opentype'), url(../fonts/FbEmeq-Bold.otf) format('otf'), url(../fonts/FbEmeq-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'OpenSans-Bold';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(../fonts/FbPractica-Regular.eot);
    src: url(../fonts/FbPractica-Regular.otf);
    src: url(../fonts/FbPractica-Regular.otf) format('embedded-opentype'), url(../fonts/FbPractica-Regular.otf) format('otf'), url(../fonts/FbPractica-Regular.otf) format('truetype');
    /* src: url(../fonts/OpenSans-Bold.ttf);
    src: url(../fonts/OpenSans-Bold.ttf);
    src: url(../fonts/OpenSans-Bold.ttf) format('embedded-opentype'), url(../fonts/OpenSans-Bold.ttf) format('otf'), url(../fonts/OpenSans-Bold.ttf) format('truetype'); */
    unicode-range: U+0025-00FF;
}

:root {
    /* --MyFont: 'FbPractica_Regular', 'sans-serif'; */
    --MyFont: "Roboto", "Helvetica", "Arial", sans-serif;
    --Arial: 'Arial', 'sans-serif';
    --SecondFont: "Roboto", "Helvetica", "Arial", sans-serif;
    /* --SecondFont: 'FbEmeq-Bold', 'sans-serif'; */
    --ROBOO: 'Roboto', 'sans-serif';
    font-size: 15px;
}

body {
    margin: unset !important;
}

.english {
    font-family: "Arial";
    font-size: 80%;
    direction: ltr;
}

.goRtl {
    direction: rtl;
}

.onTopOfAll {
    z-index: 99999 !important;
}

.col-stretch {
    display: flex;
    justify-content: stretch;
    float: right;
    direction: rtl;
}

.centerText {
    text-align: center;
}

.leftText {
    text-align: left;
}

.rightText {
    text-align: right;
}

.centerMargin {
    margin: 0 auto;
}

.asTable {
    display: table;
}

.bold {
    font-weight: bold;
}

.vSmall {
    font-size: var(--smallFont);
}

.noBoldvSmall {
    font-size: var(--mediumFont) !important;
}

.mediumst {
    font-size: var(--mediumFont);
}

.regular {
    font-size: var(--regularFont);
}

.big {
    font-size: var(--bigFont);
}

.veryBig {
    font-size: var(--veryBigFont);
}

.huge {
    font-size: var(--huge);
}

.radiusBord-5 {
    border-radius: 5px;
}

.radiusBord-10 {
    border-radius: 10px;
}

.MyTextArea {
    width: 80%;
    min-height: 50px;
    border: 1px solid var(--lGray);
}

.veryBigFontImportant,
.veryBigFontImportant p,
.veryBigFontImportant span,
.veryBigFontImportant div,
.veryBigFontImportant bold {
    font-size: var(--veryBigFont) !important;
    line-height: 2rem;
}

.spaceRow {
    height: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-top: 1px solid var(--lGray);
}

.smallPadding {
    padding: 5px;
}

.bigPadding {
    padding: 10px;
}

.spacePad {
    padding-top: 5px;
    padding-bottom: 5px;
}

.spacePadBig {
    padding-top: 10px;
    padding-bottom: 10px;
}

.spaceTop {
    padding-top: 5px;
}

.spaceTopBig {
    padding-top: 10px;
}

.spaceTopVeryBig {
    padding-top: 20px;
}

.spaceBottomBig {
    padding-bottom: 10px;
}

.spaceButtom {
    padding-bottom: 5px;
}

.spaceButtomBig {
    padding-bottom: 5px;
}

.spaceButtomVeryBig {
    padding-bottom: 20px;
}

.spacePaddingRight {
    padding-right: 5px;
}

.spacePaddingRightBig {
    padding-right: 10px;
}

.spacePaddingRightVeryBig {
    padding-right: 15px;
}

.spaceMargeSmall {
    margin-bottom: 5px;
    margin-top: 5px;
}

.spaceMargeBottombig {
    margin-bottom: 10px;
}

.spaceMargeTopSmall {
    margin-top: 5px;
}

.spaceMargeTopbig {
    margin-top: 10px;
}

.spaceMargeRight {
    margin-right: 5px;
}

.spaceMargeRightbig {
    margin-right: 10px;
}

.spaceMargeLeftbig {
    margin-left: 10px;
}

.grayText {
    color: var(--GrayPlus);
}

.wrapPadding {
    /* padding: 2px; */
    padding-bottom: 15px;
}

.fullRow {
    width: 100%;
}

.halfRow {
    width: 50%;
}

.overflowHid {
    overflow: hidden;
}

.line1Rem {
    line-height: 1rem;
}

.line2Rem {
    line-height: 2rem;
}

p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

a {
    text-decoration: none;
    color: black;
}

a:visited {
    color: black;
}

textarea {
    font-family: var(--MyFont);
}

.flexMe {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.pointing:hover {
    cursor: pointer;
}

.rSideFirst {
    width: 10%;
    float: right;
    min-height: 1px;
}

.rSideMiddle {
    width: 70%;
    float: right;
}

.rSideBig {
    width: 80%;
    float: right;
    line-height: 25px;
}

.lSideSmall {
    width: 20%;
    float: left;
}

.textAr {
    border-color: lightgray;
}

.fixedMe {
    position: fixed !important;
    z-index: 10000;
}

.rSideMedBig {
    width: 59%;
    float: right;
    line-height: 25px;
}

.dirLeft {
    direction: ltr;
}

.dirRight {
    direction: rtl;
}

.onRight {
    float: right;
}

.onLeft {
    float: left;
    margin-left: 5px;
}

.noMargin {
    margin: unset !important;
}

.noPadding {
    padding: unset !important;
}

.noSidesPadding {
    padding-left: unset !important;
    padding-right: unset !important;
}

.spacePadRight {
    padding-right: 5px;
}

.textRight {
    text-align: right;
}

.textLeft {
    text-align: left;
}

.theValBlock {
    overflow: hidden;
    position: relative;
    ;
}

.theValBlock .showMe {
    display: block;
    position: relative;
    font-size: 110%;
}

.showMe {
    display: block;
}

.hideMe {
    display: none;
}

.backWhite {
    background-color: white !important;
    overflow: hidden;
}


/* textField */

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root,
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
    left: unset !important;
    right: 0;
    transform-origin: top right !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--mainColor) !important;
}

.css-ghsjzk-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid var(--mainColor) !important;
}

.sun-editor .se-btn-select .txt {
    flex: auto;
    text-align: right;
    font-family: 'Tahoma', sans-serif;
}

.okButtonColor {
    background-color: green;
}

.divAsIcon {
    display: table;
    float: right;
}

.divAsIconLeft {
    display: table;
    float: left;
}

.rdbStatSelect {
    flex-direction: row !important;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: lightgray !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: lightgray !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: lightgray !important;
}

.toast-message {
    direction: rtl;
    text-align: right;
}

.hideMe {
    display: none !important;
}

.theIconsColor {
    background-color: transparent;
    color: var(--mainColor) !important;
    font-size: 160%;
}

.theIconsColor:hover {
    color: var(--mainColorHover) !important;
}

.theSecIconsColor {
    background-color: transparent;
    color: black !important;
    font-size: 160%;
}

.theSecIconsColor.onLeft {
    font-size: unset;
}

.theSecIconsColor:hover {
    color: var(--mainColorHover) !important;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    direction: rtl;
}

.onLineInROw {
    line-height: 100%;
}

.clntBlok {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: black;
    background-color: white;
    border: 1px solid var(--lGray);
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.myButton {
    min-width: 200px;
    display: inline-block;
    padding: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: var(--mainColor);
    border: none;
    border-radius: 0px;
    width: 100%;
}

.myButton:hover {
    background-color: var(--mainColorHover)
}

.maxTxtAreaHeight {
    max-height: 100px;
    overflow: auto;
}

.ordertblBody {
    direction: rtl !important;
}

.ordDetailsDiv {
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 15px 7px;
    margin-bottom: 5px;
}

.myButton:active {
    background-color: var(--mainColorHover);
    box-shadow: 1px 3px 8px #999;
    transform: translateY(4px);
}

.saccsValInput {
    font-size: var(--smallFont);
    margin-right: 15px;
}

.innnerTD {
    vertical-align: top !important;
}

.innnerRow:nth-child(even) {
    background: #CCC
}

.innnerRow:nth-child(odd) {
    background: #FFF
}

.minSpacePx {
    height: 15px;
}

.TextBoxSetWrap .sun-editor .se-toolbar.se-toolbar-sticky {
    position: relative;
}

.TextBoxSetWrap .sun-editor .se-toolbar {
    top: 0 !important;
}

.TextBoxSetWrap .sun-editor .se-toolbar-sticky-dummy {
    display: none !important;
}

.boldTitle span {
    font-weight: bold;
}

.smallColmn {
    width: 30px;
}

.checkbox-list-tbls {
    max-height: 100px;
    overflow: auto;
}

.scrlBtn {
    color: var(--mainColor);
    /* transform: rotate(180deg); */
    position: fixed;
    bottom: 105px;
    left: 10px;
    font-size: 260% !important;
    opacity: 0;
    z-index: 1000;
    transition: opacity 5s;
    max-width: 50px;
    border: 1px solid var(--mainColor);
    background-color: white;
    border-radius: 50%;
}

.scrolBtnShow {
    opacity: 1;
    -webkit-transition: opacity 5s;
    -moz-transition: opacity 5s;
    -o-transition: opacity 5s;
    transition: opacity 5s;
}