/* .printOrderPage {
    display: none;

} */

.onlyOnPrint {
    display: none;
}

#ifmcontentstoprint {
    visibility: hidden;
}

@media print {

    #ms-side-nav {
        display: none !important;
    }

    .breadcrumb {
        display: none !important;
    }

    .reportsBtnMenu .btnBigMnu {
        display: none !important;
    }

    .buisSelectOnReports {
        width: 900px !important;
        max-width: unset !important;
    }

    .buisSelectOnReports,
    .buisSelectOnReports fieldset {
        border: none;

    }

    .buisSelectOnReports button {
        display: none;
    }

    .buisSelectOnReports input {
        font-size: 30px;
    }

    .printingCloser {
        /* background-color: black; */
        max-width: 380px;
        margin: 0 auto;
        overflow: hidden;
        direction: rtl;
        text-align: right;
    }

    .printright {
        float: right;
        display: table;
    }

    .no_print {
        display: none;
    }

    .onlyOnPrint {
        display: block;
    }

    th.blackOnPrint {
        color: black !important;
        background-color: lightgray !important;
    }

    .printOrderPage {
        display: block;
        max-width: 380px;
        float: right;
        text-align: right;
        font-family: var(--MyFont) !important;
        direction: rtl;
    }


    .addressLine {
        padding-bottom: 5px;

    }

    .addressLine div {
        float: right;
        margin-right: 3px;
        font-weight: bold;
    }

    .itemOrd {
        overflow: hidden;
        position: relative;
    }

    .inTot {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .mountDiv {
        width: 5%;
    }

    .detailsDiv {
        width: 100%;
        text-align: right;
    }

    .priceDiv {
        width: 7%;
    }


    .semBig,
    .semBig p,
    .semBig div,
    .semBig span {
        font-size: 25px;
        font-family: var(--ROBOO) !important;
    }

    .mediBig {
        font-size: 22px;
        font-family: var(--ROBOO) !important;
    }

    .smalBig {
        font-size: 18px;
        font-family: var(--ROBOO) !important;
    }

    .medBig,
    .medBig p,
    .medBig div,
    .medBig span {
        font-size: 30px;
        font-family: var(--ROBOO) !important;
    }



    .mountDiv {
        min-width: 30px;
        line-height: 100%;
    }

    .itemOrd {
        overflow: hidden;
        width: 100%;
    }

    .inTot {
        float: left;
        position: relative;
    }

    .ordItmLine .rSideMiddle {
        width: 100%;
    }



    .prName {
        width: 78%;
    }

    .ms-content-wrapper {
        padding: 0px 0px 0px 5px !important;

    }
}