.trTable {
    height: 60px;
}

@media only screen and (max-width: 1000px) {
    .UpdateOrderDetailsWrap {
        width: 98%;
        height: 98%;
        min-height: 300px;
        background-color: white;
        margin: auto;
        direction: rtl;
        text-align: right;
    }

    .UpdateOrderDetailsWrap .MuiPaper-root {
        margin: 2px;
    }

    .LastLstDiag {
        width: 100%;
        height: 100%;
        margin-top: 0 !important;
        /* position: absolute; */
        z-index: 5000 !important;
    }

    .onLeft.fixinMobi {
        width: unset;
    }

    .onRight.fixinMobi {
        width: unset;
    }

    .textSizeDiag {
        width: 100%;
    }

    .KoponDetValPop {
        width: 90%;
        margin: 6%;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
    }

    .css-3dah0e-MuiModal-root-MuiDialog-root {
        z-index: 9001 !important;
    }

    .buisSelectOnReports {
        width: 100% !important;
    }

    .OrderDispValPop,
    .OrderDispValPop div {
        padding: 1px 5px 2px 1px !important
    }

    .sendiStatus {
        max-width: 500px;
    }
}

@media only screen and (max-width: 700px) {
    :root {
        --fontFSize: 15px;
    }

    .ms-content-wrapper {
        padding: 2px !important;
        min-height: 500px;
    }

    .theBigTbl {
        font-size: 100%;
        min-height: 500px;
        width: 96%;
        margin: 0 auto;
    }

    .specTableBlock {
        font-size: 100%;
        /* min-height: 500px; */
        width: 98%;
        margin: 2px auto;
    }

    .TextBoxSetWrap {
        width: 100%;
        height: 100%;
        min-height: 300px;
        background-color: transparent;
        margin: auto;
        direction: rtl;
        text-align: right;
    }

    .TextBoxSetWrap .sun-editor .se-btn {
        float: left;
        width: 15px;
        height: 19px;
        border: 0;
        border-radius: 4px;
        margin: 4px !important;
        padding: 3px;
        font-size: 12px;
        line-height: 27px;
    }

    .TextBoxSetWrap .MuiPaper-root {
        margin: 0px !important;
    }

    .orderItminList:nth-child(odd) {
        background-color: var(--lGray);
    }

    /* 
    .orderItminList:nth-child(odd) .orderItmFlo {
        background-color: var(--lGray);
    } */
    .orderItminList:nth-child(even) {
        background-color: white;
    }

    .tempoOrdRow .orderItmFlo,
    .tableOrdWaiting .orderItmFlo {
        background-color: transparent !important;
    }

    .orderItminList {
        border: 1px solid var(--lGray);
        padding: 15px 5px;
    }

    .orderItminList .statusCell {
        width: 70%;
        font-weight: 500;
        font-size: var(--veryBigFont) !important;
        line-height: 1;
        letter-spacing: 0.02857em;
        padding: 3px 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .orderItmFlo {
        position: absolute;
        left: 0;
        min-width: 90px;
    }

    .orderPlaceBlockDiag {
        width: 98%;
        height: 95%;
        min-height: 250px;
        background-color: white;
        margin: auto;
    }

    .orderPlaceBlockDiag .MuiPaper-root {
        width: 100% !important;
        margin: 0;
    }

    .orderPlaceBlock {
        direction: rtl;
        width: 95%;
        height: 90%;
        min-height: 250px;
        background-color: white;
        margin: auto;
        max-width: unset;
        text-align: right;
    }

    .col-md-12.ordrspanel,
    .col-xl-12.ordrspanel,
    .buisTollBarMobi .col-md-12,
    .buisTollBarMobi .col-md-12 {
        padding: 0;
    }

    .buisTollBar {
        display: table;
        position: relative;
        right: unset;
        padding: 0px;
        padding-top: 10px;
        margin: 0 auto;
        margin-bottom: 0px;
        width: calc(100% - 15px);
    }

    .ordsDisp {
        width: 100% !important;
    }

    .ordsDisp .timerTd div {
        line-height: 1.5rem;
    }

    .ordsDisp .theSBtn {
        margin: unset !important;

    }

    .ordsDisp .statusCell {
        width: 50%;
        font-weight: 500;
        font-size: var(--regular) !important;
        line-height: 1;
        letter-spacing: 0.02857em;
        padding: 0 !important;
        margin: unset !important;
        /* margin-top: 5px !important; */
        /* margin-bottom: 10px; */

    }

    .ordsDisp .orderItmTxt a {
        font-size: inherit;
    }

    .ordsDisp .timerTd {
        min-height: 25px;
    }

    .ordsDisp .sendiStatus {
        font-size: 2vh;

    }

    .ordsDisp .sendiStatus button {
        width: 100%;
        white-space: nowrap;
        top: 0;
        margin-top: 0px !important;
    }

    .ordsDisp .sendiStatus button span {
        width: 100%;
        direction: rtl;
        margin: 0 auto;
        display: block;
        text-wrap: pretty;
        height: 1.5rem;
        line-height: 1.5rem;
        font-weight: bold;
    }



    .refAndSound {
        display: table;
        float: left;
        width: 10%;
    }

    .refAndCreate {
        display: table;
        float: left;
        width: 30%;
    }

    .soundONOF svg,
    .RefreshBtn svg,
    .RefreshBtnTblOrdr svg {
        font-size: 50% !important
    }

    .orderItmTxt {
        width: 100% !important;
    }

    .menuSectionsAcord .card-header {
        padding-right: 25px !important;
    }

    .acoordBtns {
        position: absolute;
        left: 25px;
    }

    .card-body {
        padding: 0px !important;
    }

    .prodOptsWrap {
        width: 100%;
    }

    .chopsBlok,
    .mainMnuimgBlok {
        padding: 1%;
        width: 50%;
    }

    .accordion .card-header i::before {
        font-size: inherit !important;
    }

    .ms-content-wrapper.SetBlock .col-md-12 {
        padding: 2%;
        background-color: white;
    }

    .accordion>.card>.card-header .fullRow {
        padding-top: 30px;
    }

    .tblHours .txtField {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .orderItminList div,
    .orderItminList span,
    .orderItminList p,
    .orderItminList a {
        font-family: var(--SecondFont);
        line-height: 1;
    }

    .orderItminList .timerTd {
        text-align: center;
    }

    .ms-panel-header,
    .ms-panel-body,
    .ms-panel-footer {
        padding: unset !important;
    }

    .SetBlock .ms-panel-header {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .SetBlock .sun-editor {
        width: 100% !important;
    }

    .SetBlock .selectInBlock {
        font-weight: bold;
        font-size: var(--bigFont);
    }

    .noPaddOnMobile {
        padding: unset !important;
    }

    .CloseDelaydrp {
        overflow: hidden;
        display: table;
        margin-top: 0px;
    }

    .CloseDelaydrp .selectInBlock {
        font-weight: normal !important;
        font-size: var(--fontFSize);
        border: 1px solid lightgray;
        padding: 10px 5px 10px 36px;
        border-radius: 5px;
    }

    .ReportTypeSelect {
        width: 98%;
        font-weight: 500;
        font-size: var(--veryBigFont) !important;
        line-height: 1;
        letter-spacing: 0.02857em;
        padding: 7px 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }



    .ms-panel-header .d-flex:first-child {
        flex-direction: inherit;
    }

    .breadcrumb {
        padding: 0px;
        margin-bottom: 0px;
    }


    .popMax {
        width: 96%;
    }

    .popMax .MuiDialogContent-root {
        padding: 0px;
    }




}