:root {
    --mainColor: #21ccd4;
    --mainColorHover: #62b4b8;
    --mainColorHover2: #5b9da0;
    --seconderyColor: #aeeef1;
    --redWhineColor: rgb(143, 45, 45);
    --specialOrangeColor: rgb(248, 110, 5);
    --lGray: #d7dce4;
    --GrayPlus: #989ca1;
    --smallFont: 70%;
    --mediumFont: 80%;
    --regularFont: 100%;
    --bigFont: 120%;
    --veryBigFont: 140%;
    --huge: 160%;
    --swiper-theme-color: #fec000 !important;
}

.ms-content-wrapper,
.ms-panel,
.ms-panel-fh {
    direction: rtl;
    text-align: right;
}

.tabsInputs label {
    font-weight: bold;
}

.spaceRight {
    margin-right: 10px;
}

.soundONOF,
.RefreshBtn {
    background-color: var(--mainColor) !important;
    border-radius: 10px !important;
    padding: 5px !important;
    font-size: 50px !important;
    margin: 5px !important;
    display: table-cell;
}

.RefreshBtnTblOrdr {
    background-color: var(--mainColor) !important;
    border-radius: 10px !important;
    padding: 5px !important;
    font-size: 50px !important;
    margin: 2px !important;
    display: table-cell;
    margin-top: 5px !important;
}

.VisiblPassBtn {
    padding: 0px !important;
    font-size: 50px !important;
    /* margin: 5px !important; */
    display: table-cell;
}

.visibleHover:hover {
    color: var(--mainColorHover);
    cursor: pointer;
}

.ChkMyButton {
    background-color: var(--mainColor) !important;
    border-radius: 10px !important;
    padding: 5px !important;
    font-size: 25px !important;
    margin: 5px !important;
    display: table-cell;
}

.refAndSound {
    display: table;
    float: left;
}

.refAndCreate {
    display: table;
    float: left;
}

.soundONOF:hover,
.RefreshBtn:hover,
.RefreshBtnTblOrdr:hover {
    background-color: var(--mainColorHover) !important;
}

.soundONOF svg,
.RefreshBtn svg,
.RefreshBtnTblOrdr svg {
    font-size: 40px !important;
}

.firstlogo {
    width: 100%;
    overflow: hidden;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #6c757d;
    content: " / ";
}

.btnIteminStock {
    left: 73px;
    position: absolute;
    width: 135px;
    height: 18px;
}

.btnIteminStock input[type="button"] {
    width: 48%;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    margin-left: 2%;
}

.InStock {
    background-color: unset;
}

.notInStock {
    background-color: red;
    color: white;
}

.inSetMe {
    top: -3px;
    position: relative;
}

.smallFont {
    font-size: var(--smallFont);
}

.mediumFont {
    font-size: var(--mediumFont);
}

.explanation {
    position: relative;
    width: 100%;
    display: block;
    top: -8px;
    min-height: 17px;
}

.roEvenOdd {
    width: 100%;
    padding-top: 10px;
}

.roEvenOdd:nth-child(even) {
    background: #f3f3f3
}

.approveLine {
    min-height: 68px;
    overflow: hidden;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    background-color: white;
    /* width: calc(100% - 259px); */
    /* padding: 30px; */
    z-index: 4000;
}

.buisSelectAddUser {
    position: relative;
    /* top: 80px; */
    line-height: 1.2rem;
    padding: 5px;
    border-radius: 5px;
    width: 190px !important;
    margin-left: 10px;
}

.buisSelectOnReports {
    right: 10px;
    position: relative;
    /* top: 80px; */
    line-height: 1.2rem;
    padding: 5px;
    border-radius: 5px;
    width: 20% !important;
    max-width: 180px;
    margin-left: 10px;
}

.buisSelectOnReportsMobi {
    width: 100% !important;
    line-height: 1.2rem;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: unset !important;
}

.reportCalcsWindow {}

.calcBigBtn {
    width: 20% !important;
    max-width: 120px !important;
    margin: 0.5% !important;
    line-height: 1;
    font-size: var(--regularFont) !important;
    font-family: var(--MyFont) !important;
    height: 35px;
}

.buisSelectOnTBAR {
    left: 10px;
    position: absolute;
    top: 80px;
    line-height: 1.2rem;
    padding: 5px;
    border-radius: 5px;
    width: 20% !important;
    max-width: 180px;
}

.koponcreateBuisLIst {
    left: 10px;
    position: relative;
    line-height: 1.2rem;
    padding: 5px;
    border-radius: 5px;
    width: 100% !important;
    max-width: 180px;
}

.orderPlaceBlock .buisSelectOnTBAR {
    position: absolute;
    top: 35px;
    left: calc(6% + 5px);
    min-width: 190px;
}

.buisSelectOnTBARMobi {
    width: 100% !important;
    line-height: 1.2rem;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: unset !important;
}

.buisSelectOnTable {
    width: calc(100% - 10px) !important;
    left: 10px;
    position: relative;
    line-height: 1.2rem;
    padding: 5px;
    border-radius: 5px;
}

.buisSelectOnBlock {
    width: calc(100% - 10px) !important;
    right: 10px;
    position: relative;
    line-height: 1.2rem;
    padding: 5px;
    border-radius: 5px;
}

.lstStylish select {
    width: calc(100% - 10px);
    left: 10px;
    position: relative;
    line-height: 1.2rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.lstStylish select optgroup option {
    line-height: 1.2rem;
    padding: 10px;
}

.table td,
.table th {
    vertical-align: middle !important;
}

.specialTxt input::placeholder {
    color: black !important;
    opacity: 0.8;
}

.specialTxt>div {
    padding: 1px 30px !important;
}

.specialTxt>div button {
    position: absolute;
}

.theBigTbl .MuiCheckbox-root {
    padding: unset !important;
}

.tempoOrdRow {
    background-color: rgb(248, 110, 5, 0.7) !important;
}

.tableOrdWaiting {
    background-color: #62b4b87a !important;
}

th .chkBx,
th .chkBx svg {
    color: white;
}

.buisSelectOnTBAR legend,
.buisSelectOnTBARMobi legend,
.buisSelectOnTable legend,
.buisSelectOnBlock legend,
.buisSelectOnReports legend,
.buisSelectOnReportsMobi legend {
    width: 0;
}

.invoiceMobiTblBlock {
    padding: 5px;
}

.SetBlock {
    margin-bottom: 60px;
}

.switchMe {
    /* width: 30%; */
    min-height: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.disabledSwitch {
    background: #e9ecef !important;
}

.switchMe.inPayTab {
    margin-top: 2.5rem;
}

.switchMe .spHead {
    line-height: 20px;
    padding-right: 10px;
}

.switchMe .ms-switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.switchMe .ms-switch-slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    transition: .4s;
}

.tblOpHours {
    margin-top: 5px;
}

.txtField {
    margin-left: 10px !important;
}

.txtField .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    text-align: right;
}

.txtField .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    left: unset;
    right: 25px;
}

.txtField .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
    left: unset;
    right: 5px;
}

.txtField .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused,
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--mainColor) !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--mainColor) !important;
}

.fullRow {
    width: 100%;
    overflow: hidden;
    padding: unset;
}

.txtARLbl {
    width: 10%;
    min-width: 150px;
    position: relative;
    bottom: 20px;
}

.theFrow {
    padding: unset;
}

.tblHours.fullRow {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
}

.ordersOpts {
    width: 100%;
}

.categurysList .catsAcord {
    width: 100%;
}

.catsAcord {
    width: 100%;
}

.ordersOpts .card .collapseparent.show+.card-header,
.catsAcord .card .collapseparent.show+.card-header {
    background-color: var(--mainColor);
    color: white;
}

.ordersOpts .card .collapseparent.show+.card-header span,
.catsAcord .card .collapseparent.show+.card-header span {
    color: white;
}

.ordersOpts .card-header,
.catsAcord .card-header,
.menuSectionsAcord .card-header {
    padding-right: 50px;
    background-color: #f0f1f6;
}

.catsAcord .ms-list-item span {
    padding-right: 10px;
}

.verySmallWidImgDisp {
    width: 60px;
    height: 40px;
}

.smallImgDisp {
    width: 80px;
    height: 80px;
}

.smallWidImgDisp {
    width: 100px;
    height: 80px;
}

.medImgDisp {
    width: 110px;
    height: 80px;
}

.betweenImgDisp {
    width: 130px;
    height: 100px;
}

.mediumImgDisp {
    width: 200px;
    height: 150px;
    object-fit: contain;
    border: 1px solid lightgray;
    position: relative;
}

.mediumImgDisp img {
    width: 200px;
    height: 150px;
    object-fit: contain;
}

.sldGalItm {
    float: right;
    margin-left: 1%;
    margin-bottom: 1%;
}

.spacLable {
    height: 21px;
    width: 100%;
}

.categurysList table img {
    max-width: unset;
    border-radius: unset;
    margin-right: unset;
}

.searchWrap {
    width: 85%;
}


/******************   popUpWrapMedia    ************************/

.popUpWrapMedia {
    padding: 20px;
    position: fixed;
    right: 20px !important;
    left: 20px !important;
    top: 20px !important;
    bottom: 20px !important;
    z-index: 7000 !important;
}

.popUpWrapMedia .inDiag {
    position: fixed;
    right: 20px;
    left: 20px;
    top: 20px;
    bottom: 20px;
    background-color: rgba(255, 255, 255, 10.9);
    direction: rtl;
    text-align: right;
}

.popUpWrapMedia .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 100%;
    height: 100%;
    max-width: unset !important;
    direction: rtl;
    text-align: right;
    background-color: var(--seconderyColor) !important;
    padding: 10px;
    margin: 0px;
}

.showFromLeft {
    left: 2%;
    -webkit-transition: left 1s;
    -moz-transition: left 1s;
    -o-transition: left 1s;
    transition: left 1s;
}

.hideFromLeft {
    left: -50%;
    -webkit-transition: left 1s;
    -moz-transition: left 1s;
    -o-transition: left 1s;
    transition: left 1s;
}


/*********************** popImages ******************/

.popImagesItemWrap {
    width: 25%;
    max-width: 100px;
    border: 1px solid white;
    float: right;
    margin: 1%;
    position: relative;
}

.removeOnImages {
    position: absolute;
    top: -10px;
    right: -5px;
    z-index: 700;
}

.ViewOnImages {
    position: absolute;
    z-index: 700;
    top: -10px;
    left: -5px;
}

.ViewOnImages:hover {
    font-size: 25px;
    top: -12px;
    left: -7px;
}

.selectedImgIcon {
    position: absolute;
    z-index: 700;
    top: 25%;
    right: 30%;
    color: var(--seconderyColor);
}

.removeOnImages:hover {
    font-size: 25px;
    top: -12px;
    right: -7px;
}

.popImagesItem img:hover {
    opacity: 0.5;
}

.PopTopLine {
    overflow: hidden;
    float: right;
    width: 80%;
    direction: rtl;
    text-align: right;
}

.PopTopLine input {
    border: 1px solid lightgray;
}

.PageTopLine {
    overflow: hidden;
    float: right;
    width: 80%;
}

.PageTopLSelectImgBox {
    overflow: unset;
    left: 0;
    bottom: 50px;
    top: 90px;
    position: relative;
    display: table;
    right: 0;
    width: 100%;
}

.popUpWrapMedia .IconsLine {
    text-align: right;
}

.IconsLine {
    margin-bottom: 10px;
}

.closeDiagBtn {
    float: left;
}

.IconsLine svg {
    margin-left: 10px;
}

.IconsLine button {
    margin-left: 10px;
}

.thePosi {
    text-align: right;
    margin-left: 1%;
}

.thePosi svg {
    margin-right: 10px;
}

.popImageGalItem img {
    width: 100px;
    height: 90px;
    object-fit: contain;
}

.popImageGalItem p {
    text-align: center;
    width: 100%;
    margin: unset;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 3px;
    padding-right: 3px;
    background-color: white;
    color: black;
}

.popImagesItem img {
    width: 100px;
    height: 90px;
    object-fit: contain;
}

.popImagesItem p {
    text-align: center;
    width: 100%;
    margin: unset;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 3px;
    padding-right: 3px;
    background-color: white;
    color: black;
}

.uploadBtn {
    background-color: var(--mainColor);
}

.brd {
    text-align: right;
}

.newFolderBlock {
    width: 195px;
    position: absolute;
    background-color: white;
    padding: 15px;
}

.newFolderBlock button {
    margin-left: 20px;
}

.imgPopUp {
    width: 70%;
    height: 70%;
    min-height: 250px;
    background-color: white;
    margin: auto;
}

.imgPopUpTitle {
    text-align: center;
    margin-top: 10px;
    direction: ltr;
}

.imgPopUpTitle input {
    text-align: right;
    width: 50%;
}

.imgPopUp img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imgPopUp .css-ypiqx9-MuiDialogContent-root {
    position: relative;
    overflow: hidden;
}

.z9005 {
    z-index: 9005 !important;
}

.textSizeDiag {
    width: 50%;
    height: 20%;
    min-height: 320px;
    background-color: #fff;
    margin: auto;
    text-align: right;
    direction: rtl;
    display: flow-root;
}

.textSizeDiag .MuiDialog-container {
    display: block;
}


.textSizeDiag.CancelOrderPop {
    height: 55%;
    width: 70%;
    max-width: 650px;
    max-height: 450px;
}

.mobiPop.textSizeDiag.CancelOrderPop {
    height: 98%;
    width: 98%;
}

.mobiPop.CancelOrderPop textarea {
    height: 50px !important;
    min-height: unset !important;
}

.mobiPop.CancelOrderPop .MuiDialogContentText-root {
    width: 96% !important;
}


.CancelOrderPop .MuiRadio-root {
    padding: 0 !important;
}

.CancelOrderPop .specialRdb>span {
    padding-left: 10px !important;
}

.LastLstDiag {
    width: 50%;
    height: 70%;
    text-align: right;
    direction: rtl;
}

.TextBoxSetWrap {
    width: 50%;
    height: 70%;
    min-height: 300px;
    background-color: white;
    margin: auto;
    direction: rtl;
    text-align: right;
}

.UpdateOrderDetailsWrap {
    max-width: 1000px;
    width: 90%;
    height: 90%;
    min-height: 300px;
    background-color: white;
    margin: auto;
    direction: rtl;
    text-align: right;
    z-index: 5000 !important;
}

.UpdateOrderDetailsWrap .MuiDialog-container {
    display: block !important;
}

.UpdateOrderDetailsWrap .MuiPaper-root {
    display: block !important;
    max-width: unset !important;
}

.mediumSizeDiag {
    width: 50%;
    height: 50%;
    background-color: white;
    margin: auto;
}

.mediumSizeDiag .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.textSizeDiag .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.imgPopUp .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    direction: rtl;
    text-align: right;
    margin: unset;
}

.mediumSizeDiag .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root .textSizeDiag .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    left: unset;
    right: 0;
}

.mediumSizeDiag .css-ghsjzk-MuiInputBase-root-MuiInput-root:after .textSizeDiag .css-ghsjzk-MuiInputBase-root-MuiInput-root:after {
    border-color: var(--mainColor);
}


/*********************** / popImages ******************/


/******************  /  popUpWrapMedia    ************************/

.CheckDelete {
    z-index: 7000;
    direction: rtl;
}

.popSelectImgBox {
    overflow: auto;
    left: 0;
    /* max-height: 500px; */
    bottom: 50px;
    top: 90px;
    position: absolute;
    right: 0;
    direction: rtl;
}

.popSelectImgBox .col-md-6 {
    direction: rtl;
    text-align: right;
}

.inDiag {
    height: 95%;
    position: relative;
}

.bottomBtnsline {
    bottom: 0;
    /* top: 0; */
    width: 98%;
    height: 65px;
    position: webkit-sticky;
    position: absolute;
    background-color: var(--seconderyColor);
    z-index: 700;
}

.ms-panel-header .btn {
    margin-top: 10px;
}

@media (max-width: 1024px) {
    .approveLine {
        width: 100%;
    }
}

.activeLnk a,
.activeLnk a span {
    color: var(--mainColor) !important;
}


/******************** Reataurant Content ****************************/

.buisTollBar {
    display: table;
    position: relative;
    right: 15px;
    top: 0;
    padding: 10px;
    /* margin: 0 auto; */
    margin-bottom: 20px;
    width: calc(100% - 15px);
}

.buisTollBar .theSBtn span {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
}

.table thead tr th {
    border-top: 0;
    font-weight: 300;
}

.buisTollBar .theSBtn {
    width: 10% !important;
    max-width: 100px !important;
    margin: 0.5% !important;
    line-height: 1;
    font-size: var(--regularFont) !important;
    font-family: var(--MyFont) !important;
    height: 25px;
    color: white !important;
    background-color: var(--mainColor) !important;
}

.buisTollBar .theTbBtn {
    padding: 17px !important;
    width: 10% !important;
    max-width: 100px !important;
    margin: 0.5% !important;
    line-height: 1;
    font-size: var(--regularFont) !important;
    font-family: var(--MyFont) !important;
    height: 25px;
    color: white !important;
    background-color: var(--mainColor) !important;
}

.buisTollBar .theTbBtn span {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
}

.catAddBtnWrap {
    display: table;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.buisTollBar .css-1d6wzja-MuiButton-startIcon,
.menuTab .css-1d6wzja-MuiButton-startIcon,
.catAddBtnWrap .css-1d6wzja-MuiButton-startIcon,
.btnSPc .css-1d6wzja-MuiButton-startIcon {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
}


/********************************  MENU TAB  ****************************/

.menuTab {
    width: 100%;
}

.menuSectionsAcord .show+.card-header,
.menuSectionsAcord .show+.card-header span,
.menuSectionsAcord .show+.card-header .ms-text-danger {
    background-color: var(--mainColor) !important;
    color: white;
    font-size: 105%;
}

.whiteDang {
    color: white;
}

.addDivBtn,
.menuSectionsAcord button {
    margin-bottom: 5px !important;
}

.card-header .divAsIcon i {
    top: 10px;
}

.card-header:hover,
.acoordBtns:hover,
.acoordBtnsMobi:hover {
    background-color: var(--mainColorHover2);
}

.prod {
    text-align: right;
}

.mnuLblInpSet div,
.prodFirst {
    overflow: hidden;
}

.prodFirst label,
.prodSwitch.switchMe .spHead,
.txtOnBtn label {
    width: 10%;
    min-width: 150px;
    margin-bottom: 0.5rem;
    font-size: var(--regularFont);
}

.prodFirst input[type="text"],
.txtOnBtn input[type="text"],
.prodFirst textarea,
.prodFirst input[type="number"],
.txtOnBtn input[type="number"],
.prodFirst .input-group {
    width: calc(90% - 150px);
    min-width: 100px;
    font-size: var(--regularFont);
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
    font-weight: 500;
    font-size: var(--mediumFont) !important;
    line-height: 1;
    letter-spacing: 0.02857em;
    padding: 3px 10px;
    margin: 5px 1% !important;
}

.servTpDiv {
    padding-top: 0px;
    overflow: hidden;
    margin-bottom: 5px;
}

.servTpDiv:nth-child(even) {
    background: #f3f3f3
}

.mnuItmOptType,
.mnuItmOptPrice {
    width: calc(33% - 15px);
    float: right;
    min-width: 105px;
}

.mnuItmOptSauces {
    width: calc(20% - 15px);
    float: right;
}

.mnuItmOptSauces input {
    width: 50%;
}

.mnuItmOptType label,
.mnuItmOptPrice label,
.mnuItmOptSauces label {
    margin-left: 10px;
    display: none;
}

.mnuItmOptType input,
.mnuItmOptPrice input {
    padding-right: 5px;
    width: calc(90%);
    min-width: 100px;
    font-size: var(--smallFont);
}

.optidietDel {
    margin-right: 10px;
    position: relative;
    left: 0;
}


/* .prodSwitch.switchMe {
    width: 45%;
} */

.prodSwitch .ms-switch {
    width: 38px;
    min-width: unset;
}

.prodSwitch.switchMe .spHead {
    padding-right: unset;
}

.prodOptsWrap,
.prodOpts {
    width: 95%;
    /* border: 1px solid black; */
    overflow: hidden;
    margin-top: 0px;
}

.prodOpts .prodFirst {
    margin-top: 3px;
}

.prodOptDetWrap {
    width: 100%;
    /* border: 1px solid black; */
    overflow: hidden;
    margin-top: 5px;
}

.menuTab input:focus,
.categurysList input:focus {
    border-color: var(--mainColor);
}

.categurysList {
    margin-bottom: 65px;
}

.theCteguryImg img {
    max-width: unset;
    border-radius: unset;
}

.txtAreaCats {
    width: 100%;
}

.acordbt {
    top: 4px;
    left: 0px;
}

.acord1 {
    top: 4px;
    left: 2px;
}

.acord2 {
    top: 5px;
    left: 38px;
}

.acord3 {
    top: 5px;
    left: 21px;
}

.acord4 {
    top: -3px;
    left: 12px;
}

.acord5 {
    top: -3px;
    left: 38px;
}

.acoordIcon {
    position: absolute;
}

.stpBtns {
    width: 20%;
    position: absolute;
    left: 0;
    height: 50px;
    z-index: 500;
}

.acoordBtns {
    position: absolute;
    left: 25px;
    top: 0;
    z-index: 55;
}

.catLstBtn {
    position: absolute;
}

.saveToSilo {
    position: absolute;
    left: 0;
}

.card-body {
    padding: 10px !important;
}


/********************************  ImgAndTxt  ****************************/

.SelectimgTxtInput {
    width: 100%;
    border-color: unset;
}

.SelectimgTxt {
    border: unset !important;
}

.SelectimgTxt table img {
    max-width: unset;
    border-radius: unset;
    margin-right: 0;
}

.SelectimgTxt table {
    border: unset;
}

.SelectimgTxt .frow {
    width: 15%;
}

.SelectimgTxt .mrow {
    width: 40%;
    position: relative;
}

.SelectimgTxt textarea {
    border-color: lightgrey;
    position: absolute;
    top: 15px;
}

.SelectimgTxt .lrow {
    width: 15%;
}

.sideNavi {
    overflow: auto;
    padding-bottom: 150px;
}

.frow,
.mrow,
.lrow {
    min-width: 150px;
}

.l2row {
    width: 5%;
    max-width: 25px;
}

.l3row {
    width: 5%;
    max-width: 25px;
    text-align: center;
}

.smallBtnsLine {
    margin-bottom: 10px;
    text-align: left;
}

.iconsOnLeft {
    display: table;
    position: relative;
    margin: 0 auto;
}

.cityesSelect {
    /* top: -20px; */
    position: relative;
    /* right: 42px; */
    color: black;
}

.cityesSelect input {
    margin-left: 5px;
    margin-top: 5px;
}


/********************************  TxtAndLnk  ****************************/

.SelectTxtLnkInput {
    width: 100%;
    border-color: unset;
}

.SelectTxtLnk {
    border: unset !important;
}

.SelectTxtLnk table img {
    max-width: unset;
    border-radius: unset;
    margin-right: 0;
}

.SelectTxtLnk table {
    border: unset;
}

.SelectTxtLnk .frow {
    width: 15%;
}

.SelectTxtLnk .mrow {
    width: 40%;
    position: relative;
}

.SelectTxtLnk textarea {
    border-color: lightgrey;
    top: 15px;
}

.SelectTxtLnk .lrow {
    width: 15%;
}

.frow,
.mrow,
.lrow {
    min-width: 150px;
}

.l2row {
    width: 5%;
    max-width: 25px;
}

.smallBtnsLine {
    margin-bottom: 10px;
    text-align: left;
}

.iconsOnLeft {
    display: table;
    position: relative;
    margin: 0 auto;
}


/*******************************  BuisSelectFromList  ***********************************/

.buisSearchTxt {
    margin-right: 25px;
    margin-top: 30px;
    padding: 5px;
    min-width: 250px;
    width: 50%;
    border-radius: 5px;
    border: none;
}

.blackCheck {
    font-size: 100%;
    padding: unset !important;
    padding-left: 10px !important;
}


/*****************************  DTSearchBar  ****************************************/

.DTSearchBar {
    display: block;
    overflow: hidden;
}

.DTSearchBarWrap {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.DTSearchBarIN h6 {
    display: table;
    line-height: 250%;
    margin-left: 20px;
}

.DTSearchBarIN h6,
.catgeDrp {
    float: right;
    width: 30%;
}

.dtSearchInput {
    width: 100%;
    float: right;
    padding-right: 65px !important;
    background-color: var(--lGray) !important;
}

.dtSearchInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--GrayPlus) !important;
    opacity: 1;
    /* Firefox */
}

.dtSearchInput:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--GrayPlus) !important;
}

.dtSearchInput::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--GrayPlus) !important;
}

.dtSearchIcon {
    right: 0px;
    width: 50px;
    position: absolute !important;
}

.srchBox {
    width: 100%;
    overflow: hidden;
}

.searchSe {
    width: 30%;
    float: right;
    min-width: 200px;
}

.searchSe input {
    background-color: var(--lGray) !important;
    border: 1px solid #f0f0f7 !important;
    border-radius: 10px;
}

.catgeDrp {
    width: 30%;
}

.catgeDrp select {
    height: 45px;
    margin-right: 5px;
    padding: 11px 14px;
    border-radius: 25px;
    border: 0;
    background: #f0f0f7;
    transition: 0.3s;
    min-width: 200px;
    width: 100%;
    direction: rtl;
    background-color: var(--lGray);
}

.catgeDrp select option {
    font-size: 150%;
}

.selectInBlockMobi,
.selectInBlockMobi option {
    background-color: white;
}

.selectInBlock,
.selectInBlock option {
    background-color: white;
}

.bigStatus {
    margin-top: 5px;
}

.bigStatus .selectInBlock {
    font-weight: bold;
    padding: 10px;
    font-size: 20px;

}

.MultiBuisSelectOnTable>div {
    padding: 5px;
    white-space: normal !important;
}

.table-responsive {
    min-height: 400px !important;
}

.redCard {
    background-color: var(--redWhineColor) !important;
    color: white !important;
}

.redCard span {
    color: white !important;
}

.noActive {
    background-color: var(--redWhineColor);
    color: white;
}

.noActive option {
    background-color: white;
    color: black;
}

.noPublish {
    background-color: var(--specialOrangeColor);
    color: white;
}

.inBlue {
    color: var(--mainColor);
}

.redRow {
    background-color: var(--redWhineColor);
    color: white;
}

.noPublish option {
    background-color: white;
    color: black;
}

.invalid-feedback,
.valid-feedback {
    bottom: calc(100% + 0px);
    right: 0;
    text-align: left;
    position: absolute;
}

.ms-SideLoginImg {
    position: relative;
    height: 100%;
    background-image: url('../img/costic/default-login.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.Mui-checked {
    color: var(--mainColor) !important;
}

@media (max-width: 960px) {
    .theBigTbl table tr {
        height: 50px;
    }
}

.totalReportsLine {
    width: 100%;
    /* padding: 0.5rem; */
    overflow: hidden;
    font-weight: bold;
    text-align: left;
}

.totalsLine {
    width: 100%;
    padding: 0.5rem;
    overflow: hidden;
    font-weight: bold;
}

.timerTd {
    min-width: 150px;
}

.theBigTbl {
    font-size: 100%;
}

.selectInBlock {
    font-weight: bold;
    border: 1px solid black;
    padding: 3px;
}

.CloseDelaydrp {
    overflow: hidden;
    display: table;
    margin-top: 40px;
}

.CloseDelaydrp .selectInBlock {
    font-weight: normal !important;
    border: 1px solid lightgray;
    padding: 9px;
    border-radius: 5px;
}

.fullShow {
    width: 150px;
    height: 100px;
}

.fullShow img {
    object-fit: cover !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root.css-sghohy-MuiButtonBase-root-MuiButton-root {
    font-weight: 500;
    font-size: var(--mediumFont) !important;
    line-height: 1;
    letter-spacing: 0.02857em;
    padding: 3px 10px;
    margin: 5px 1% !important;
    background-color: var(--mainColor);
}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
    background-color: var(--mainColorHover) !important;
}

.btnBigMnu,
.css-1hw9j7s {
    /* min-height: 50px !important;
    min-width: 120px !important; */
    /* font-family: var(--SecondFont) !important; */
    font-weight: normal !important;
    font-size: var(--regularFont) !important;
    line-height: 1 !important;
    letter-spacing: 0.02857em !important;
    padding: 3px 10px !important;
    margin: 0 2px !important;
    color: white !important;
    background-color: var(--mainColor) !important;
}

.btnBigMnu_big {
    padding: 15px 20px !important;
}

.btnBigMnuLight {
    background-color: var(--GrayPlus) !important;
}

.btnBigMnu svg,
.css-1hw9j7s svg {
    margin-right: -4px;
    margin-left: 8px;
}

.btnMnuTop {
    width: 70%;
}

.btnMnuTop .btnBigMnu {
    width: 20% !important;
    max-width: 120px !important;
    margin: 0.5% !important;
    line-height: 1;
    font-size: var(--regularFont) !important;
    font-family: var(--MyFont) !important;
    height: 35px;
}

.reportsBtnMenu {
    width: 70%;
}

.reportsBtnMenu .btnBigMnu {
    width: 20% !important;
    max-width: 120px !important;
    margin: 0.5% !important;
    line-height: 1;
    font-size: var(--regularFont) !important;
    font-family: var(--MyFont) !important;
    height: 35px;
}

.lstOrderTbls,
.lstOrderTbls th {
    font-size: var(--regularFont);
    font-weight: normal;
}

.reportsPnl .table td {
    white-space: break-spaces;
}

.btnDeliveryDetail2 {
    background-color: var(--mainColorHover2) !important;
    height: 30px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin: 0 auto !important;
    width: 100% !important;
}

.btnDeliveryDetail {
    background-color: var(--mainColorHover2) !important;
    height: 52px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 100%;
    margin-top: 5px !important;

}

.btnDeliveryDetail span,
.btnDeliveryDetail2 span {
    color: white;
}

.btnMediumMnu {
    background-color: var(--mainColor) !important;
    height: 30px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.btnMediumMnuSmall {
    background-color: var(--mainColor) !important;
    height: 25px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: var(--smallFont) !important;
    margin-top: 10px !important;
    float: left;
}
.btnMediumMnuNoMini{
min-width: unset !important;
}

.selectedMnuBtnRed {
    background-color: var(--redWhineColor) !important;
}

.selectedMnuBtn,
.btnMediumMnu:hover,
.btnMediumMnuSmall:hover {
    background-color: var(--mainColorHover2) !important;
}

.myButton {
    float: left;
}

.btnAddItm {
    margin: 2px !important;
    font-size: 90% !important;
    min-height: 40px !important;
}

.buisTollBar .css-e53awj-MuiStack-root {
    display: block;
}

.ordItmLine {
    overflow: hidden;
}

.statBlock {
    overflow: hidden;
    width: 100%;
    direction: rtl;
    padding-top: 15px;
}

.dottedLine {
    border-bottom: 1px dotted black;
}

.dottedLightGray {
    width: 50%;
    margin: 0 auto;
    border-bottom: 1px dotted black;
    padding-top: 5px;
    margin-bottom: 5px;
}
.dottedFullLightGray{
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px dotted black;
    padding-top: 5px;
    margin-bottom: 5px;
}
.upordrAddrs {
    padding: 20px;
}

.lstCell {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sendiStatus {
    width: auto;
    max-width: 100px;
    min-width: 100px;
}

.sendiStatus div {
    width: 90%;
    overflow: hidden;
}

.statusCell {
    min-width: 120px;
}

.statBlock .statusCell {
    max-width: 250px;
    float: left;
}

.statBlock .onRight {
    float: right;
    margin-top: 5px;
    margin-left: 8px;
}

.usrDet {
    overflow: hidden;
    margin-bottom: 5px;
}

.usrDet input,
.usrDet textarea {
    width: calc(100% - 10px);
    margin: 0 auto;
    line-height: 45px;
    border: none;
    font-weight: bold;
    padding-right: 10px;
    overflow: hidden;
    background-color: var(--mainTurqi);
    color: black;
    border-bottom: 1px solid var(--lGray);
}

.usrDet input::placeholder,
.usrDetSmall input::placeholder {
    color: var(--GrayPlus);
}

.usrDet ::placeholder,
.usrDetSmall ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--GrayPlus);
    opacity: 1;
    /* Firefox */
}

.usrDet :-ms-input-placeholder,
.usrDetSmall :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--GrayPlus);
}

.usrDet ::-ms-input-placeholder,
.usrDetSmall ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--GrayPlus);
}

.usrDetSmall input {
    width: calc(23% - 8px);
    margin-right: 4px;
    margin-left: 4px;
    line-height: 45px;
    font-weight: bold;
    border: none;
    /* padding-right: 5px; */
    overflow: hidden;
    background-color: var(--mainTurqi);
    color: black;
    float: right;
    border-bottom: 1px solid var(--lGray);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    box-shadow: 0 0 0 30px var(--mainTurqi) inset !important;
    -webkit-box-shadow: 0 0 0 30px var(--mainTurqi) inset !important;
}

.usrDetChk {
    overflow: hidden;
}

.errorMe input {
    border: 2px solid red;
}

.errorMe span svg {
    color: red;
}

.errorLine p {
    color: red;
    font-weight: bold;
}

.readItLink {
    color: blue !important;
    text-decoration: underline;
}

.usrDetChk span,
.usrDetChk a {
    float: right;
    line-height: 25px;
    padding: 0px 5px 0px 0px;
}

.ConDetailsBtn {
    height: 45px;
    background-color: var(--mainColor);
    border-radius: 15px;
    position: relative;
    width: 95%;
    margin: 0 auto;
    text-align: center;
    line-height: 45px;
    border: none;
}

.DelORGet {
    width: 50%;
    float: right;
}

.inputSPMain {
    direction: ltr;
}

.usersHeads {
    font-weight: bold;
    font-size: 150%;
}

.CRTypeSelect,
.RestStatusSelect,
.eraseEditBlock {
    overflow: hidden;
    display: table;
    margin: 0 auto;
}

ul.invoice-date {
    float: left;
}

.notInstock {
    background-color: rgb(155, 91, 91);
    color: white;
}

.addDivBtn {
    font-weight: 500;
    font-size: var(--regularFont) !important;
    line-height: 1;
    letter-spacing: 0.02857em;
    padding: 3px 10px;
    margin: 0 2px !important;
    color: white;
    background-color: var(--mainColor) !important;
    font-family: var(--mainFont);
}

.rmvDivBtn {
    font-weight: 500;
    font-size: var(--regularFont) !important;
    line-height: 1;
    letter-spacing: 0.02857em;
    padding: 3px 10px;
    margin: 0 2px !important;
    color: white;
    background-color: rgb(155, 91, 91) !important;
    font-family: var(--mainFont);
}

.rmvDivBtn2 {
    font-weight: 500;
    font-size: var(--regularFont) !important;
    line-height: 1;
    letter-spacing: 0.02857em;
    padding: 3px 10px;
    margin: 0 2px !important;
    color: white;
    background-color:rgb(96 155 91) !important;
    font-family: var(--mainFont);
}

.releaseDelayBtn {
    font-weight: 500;
    font-size: var(--regularFont) !important;
    line-height: 1;
    letter-spacing: 0.02857em;
    padding: 3px 10px;
    margin: 0 2px !important;
    color: white;
    background-color: var(--mainColor) !important;
    font-family: var(--mainFont);
}

.bxCheckOnLst {
    display: inline-block;
    margin: 0 auto;
    padding: 0px 5px;
}

.bxCheckOnLst:hover {
    border-inline: 1px solid lightgray;
}

.btnKoponMnuTop {
    width: 100%;
}

.btnKoponMnuTop .btnBigMnu {
    width: 20% !important;
    max-width: 120px !important;
    margin: 0.5% !important;
    line-height: 1;
    font-size: var(--regularFont) !important;
    font-family: var(--MyFont) !important;
    height: 35px;
}

.koponInput {
    width: 100% !important;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px 10px;
}

.myPopDiag {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9000;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: auto;
}


/* גודל פופאפ פנימי  */

.diagSmallWidth {
    max-width: 500px;
    height: 90%;
}

.popDialogContent {
    width: 90%;
    margin: 4% auto;
    height: 90%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
}

.popUpCloseBtn {
    position: fixed;
}

.popSetter {
    text-align: right;
    direction: rtl;
}

.popSetter .MuiDialog-container {
    width: 90%;
    margin: 4% auto;
    height: auto;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.popMax {
    width: 90%;
    margin: 4% auto;
    height: auto;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.popMax500 {
    max-width: 500px;
}

.popMax800 {
    max-width: 800px;
}

.grayFrame {
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 15px 7px;
    margin-bottom: 10px;
}

.toast-inSidePop {
    position: absolute;
}

.OrderDispPop {
    text-align: right;
    direction: rtl;
}

.OrderDispValPop {
    width: 90%;
    margin: 4% auto;
    height: auto;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
}

.KoponDetValPop {
    width: 90%;
    margin: 4% auto;
    height: auto;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
}

.kopActivatorsPop {
    width: 90%;
    margin: 4% auto;
    height: auto;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 700px;
}

.css-1ozefeb {
    z-index: 9001 !important;
}

.btnsDiag {
    overflow: hidden;
    margin-top: 10px;
}

.btnsDiag button {
    margin-right: 5px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.reportsIMgSelect {
    left: 10px;
    top: 20px;
    z-index: 5000;
}

.posRelative {
    position: relative;
}

.posAbsolute {
    position: absolute;
}

.reportsIMgItem {
    margin: 0 auto;
    width: auto;
    height: auto;
}

.reportsHead {
    display: table;
    padding-top: 5px;
    padding-right: 30px;
    font-size: 35px;
    margin-bottom: 10px;
}

.autoCOmpItem {
    width: auto;
}

.specialBlk {
    position: relative;
    min-height: 60px;
}

.specialBlkWr {
    position: relative;
    min-height: 60px;
    min-width: 180px;
}

.autoCOmpItem .MuiAutocomplete-popper {
    z-index: 9005;
    position: fixed !important;
}

.MuiButton-endIcon {
    display: inherit;
    margin-right: 10px !important;
    margin-left: 0px !important;
}

.MuiButton-startIcon {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.popupZINDEX {
    z-index: 5000;
}

.orderPlaceBlockDiag {
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: 95%;
    min-height: 250px;
    background-color: #fff;
    margin: auto;
}

.btnstblOrdrs {
    padding-bottom: 15px;
}

.orderPlaceBlockDiag .MuiPaper-root {
    width: 100% !important;
    margin: 0;
}

.orderPlaceBlock {
    direction: rtl;
    width: 100%;
    height: 90%;
    min-height: 250px;
    background-color: white;
    margin: auto;
    max-width: unset;
    text-align: right;
}

.inputsStyle {
    border-radius: 5px;
    background-color: white;
    color: black;
    margin: 5px;
    border: 1px solid black;
    padding-right: 10px;
}

.orderPlaceBlockDiag .inputsStyle {
    width: 100%;
}

textarea.inputsStyle {
    width: 95% !important;
}

.orderPlaceBlockDiag .badge {
    width: 105% !important;
}

.inputsDropDownStyle,
.inputsDropDownStyle option {
    border-radius: 5px;
    background-color: white;
    color: black;
    margin: 5px;
    padding: 5px;
    min-width: 165px;
}

.timsFromToTables,
.timsFromToTables option {
    border-radius: 5px;
    background-color: white;
    color: black;
    margin: 5px;
    padding: 5px;
    /* min-width: 165px; */
}

.TextBoxSetWrap {
    width: 50%;
    height: 70%;
    min-height: 300px;
    background-color: white;
    margin: auto;
    direction: rtl;
    text-align: right;
}

.UpdateOrderDetailsWrap {
    max-width: 1000px;
    width: 90%;
    height: 90%;
    min-height: 300px;
    background-color: white;
    margin: auto;
    direction: rtl;
    text-align: right;
    z-index: 5000 !important;
}

.UpdateOrderDetailsWrap .MuiDialog-container {
    display: block !important;
}

.UpdateOrderDetailsWrap .MuiPaper-root {
    display: block !important;
    max-width: unset !important;
}

.react-datepicker,
.react-datepicker__day-names,
.react-datepicker__current-month,
.react-datepicker__header {
    background-color: white;
    /* Change this color to your desired background color */
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: white;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__current-month {
    color: black;
}

.react-datepicker__day--outside-month {
    color: #989ca1;
}

.react-datepicker__day:hover {
    background-color: white;
    color: black;
}

.inGreen {
    color: green !important;
}

.inRed,
.inRed p {
    color: red !important;
}

.inYellow {
    color: yellow !important;
}

.inOrange {
    color: orange !important;
}

.inBlack {
    color: black !important;
}

.inPurpple {
    color: purple !important;
}

.inBGYellow {
    background-color: #dddd38 !important;
    color: black !important;
}

.inBGOrange {
    background-color: orange !important;
    color: black !important;
}

.inBGBlack {
    background-color: black !important;
    color: white !important;
}

.toast-inPop {
    position: relative;
    top: 5%;
    left: 20%;
}

.toast-inPop-Mobi {
    position: relative;
    bottom: 0;
    left: 0;
}

.toast-inPop-Mobi .toast-success,
.toast-inPop .toast-success {
    opacity: 1 !important;
}

.inBGGreen {
    background-color: green !important;
}

.inRed,
.inRed p {
    color: red !important;
}


.inBGPurpple {
    background-color: #445cc8 !important;
    color: white !important;
}

.inBGWhite {
    background-color: white !important;
}

.inBGGreen>span,
.inBGPurpple>span {
    color: white !important;
}

.mediumSizeDiag {
    width: 50%;
    height: 50%;
    background-color: white;
    margin: auto;
}


/* מסתיר את השעות הלא רלוונטיות בבחירת שעה להזמנת מקום */

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    display: none;
}

.react-datepicker__header--time {
    padding-bottom: 32px !important;
    padding-left: 5px;
    padding-right: 5px;
}

.mediumSizeDiag .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.textSizeDiag .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.orderPlaceBlockDiag .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.imgPopUp .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    direction: rtl;
    text-align: right;
    margin: unset;
}

.heightByComps .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    height: auto !important;
    max-height: 90% !important;
}

.heightByComps.mobiPop .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-height: 100% !important;

}

.mobiPop .MuiDialogContent-root {
    padding: 0px !important;
}

.heightByComps .react-datepicker-wrapper {
    width: 100%;
}

.heightByComps .badge {
    width: 100% !important;
}

.mobiPop textarea {
    min-height: 100px;
    width: 100% !important;
}

.halfBlockWrap,
.halfBlock {
    padding: 0px !important;
}

.mediumSizeDiag .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root .textSizeDiag .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    left: unset;
    right: 0;
}

.mediumSizeDiag .css-ghsjzk-MuiInputBase-root-MuiInput-root:after .textSizeDiag .css-ghsjzk-MuiInputBase-root-MuiInput-root:after {
    border-color: var(--mainColor);
}

.printBuissReportWrap .popDialogContent {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    direction: rtl;
}

.offOrdItm {
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 15px 7px;
    margin-bottom: 10px;
}

.TheOrderListToDisp {
    position: relative;
    margin: 0 auto;
    padding-bottom: 20px;
    direction: rtl;
    z-index: 800;
    width: 100%;
}

.TheOrderShow {
    width: 90%;
    margin: 0 auto;
}


.smallDIspImageWrap {
    padding: 0px 10px 10px 10px;
    text-align: center;
}


.smallDIspImage {
    max-width: 130px;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 8px;
    margin: 0 auto;
}

.parentX {
    z-index: 8500 !important;
}